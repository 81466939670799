.App {
  text-align: center;
}

.parent {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.child {
  margin: auto;
}